import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import { IS_EMBED } from "../helpers/cha-utils";

type Props = {
  title: string;
};
const Header: React.FC<Props> = (props) => {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        {!IS_EMBED && (
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
        )}
        <IonTitle>{props.title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
export default Header;
