import { RangeValue } from "@ionic/core";
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonRange,
} from "@ionic/react";
import { chevronUpOutline, settingsOutline } from "ionicons/icons";
import classes from "./Settings.module.css";

type Props = {
  decimalValue?: number;
  onRangeUpdated: (value: RangeValue) => void;
};
const Settings: React.FC<Props> = (props) => {
  return (
    <IonAccordionGroup className={classes.iag}>
      <IonAccordion value="settings" toggleIcon={chevronUpOutline}>
        <IonItem slot="header" color="primary">
          <IonIcon
            icon={settingsOutline}
            style={{ marginRight: "5px" }}
          ></IonIcon>
          <IonLabel>Settings</IonLabel>
        </IonItem>
        <div slot="content" className={`${classes.iac} ion-padding`}>
          <div>
            <IonLabel>Fractional digits: {props.decimalValue}</IonLabel>
          </div>

          <IonRange
            ticks={true}
            snaps={true}
            min={0}
            max={14}
            value={props.decimalValue}
            onIonKnobMoveEnd={({ detail }) =>
              props.onRangeUpdated(detail.value)
            }
          >
            <IonLabel slot="start">0</IonLabel>
            <IonLabel slot="end">14</IonLabel>
          </IonRange>
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
};
export default Settings;
