import {
  IonInput,
  IonItem,
} from "@ionic/react";
import TextFieldTypes from "../types/TextFieldTypes";
import classes from "./BasicTextBox.module.css";

type Props = {
  name?: string;
  type: TextFieldTypes;
  label: string;
  subLabel?: string;
  short: string;
  value: number;
  textSize?: 1 | 2 | 3 | 4 | 5 | 6;
  onIonInput?: (target: HTMLIonInputElement) => void;
};
const BasicTextBox: React.FC<Props> = (props) => {
  let inputClasses = classes.input;
  if (props.textSize) inputClasses += " " + classes["ts" + props.textSize];
  return (
    <div className={classes.root}>
      <IonItem lines="none">
        <div
          className={`${classes.short} ${classes["l" + props.short.length]}`}
        >
          {props.short}
        </div>
        <div className={classes.second}>
          <div className={classes["cha-label"]}>{props.label}</div>
          <div className={classes["cha-sub-label"]}>{props.subLabel}</div>
        </div>
        <IonInput
          name={props.name}
          className={`${inputClasses} ion-no-padding`}
          type={props.type}
          value={props.value}
          maxlength={10}
          clearInput={true}
          onIonInput={(e) =>
            props.onIonInput &&
            props.onIonInput(e.target as HTMLIonInputElement)
          }
        ></IonInput>
      </IonItem>
    </div>
  );
};
export default BasicTextBox;
