import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonReorder,
  IonReorderGroup,
  IonRow,
  ItemReorderEventDetail,
  useIonToast,
} from "@ionic/react";
import {
  close,
  copyOutline,
  downloadOutline,
  trashOutline,
} from "ionicons/icons";
import myUtil from "../helpers/cha-utils";
import ChaBasicListType from "../types/BasicListType";
import classes from "./ListItems.module.css";

type Props = {
  items: ChaBasicListType[];
  decimalValue?: number;
  onDelete?: (key: number) => void;
  onClear?: () => void;
  onListUpdate?: (event: CustomEvent<ItemReorderEventDetail>) => void;
};
const ChaListItems: React.FC<Props> = (props) => {
  const [present] = useIonToast();
  const showToast = (
    message: string,
    position: "top" | "middle" | "bottom"
  ) => {
    present({
      message: message,
      duration: 1500,
      position: position,
    });
  };

  const itemsJSX = props.items.map((item) => (
    <IonItem lines="full" key={item.key}>
      <IonReorder slot="start"></IonReorder>
      <IonGrid>
        <IonRow>
          <IonCol className="ion-text-right">
            {myUtil.formatNumber(item.a, props.decimalValue)}
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            <IonLabel>⇆</IonLabel>
          </IonCol>
          <IonCol className="ion-text-right">
            {myUtil.formatNumber(item.b, props.decimalValue)}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton
        color="danger"
        slot="end"
        onClick={() => props.onDelete && props.onDelete(item.key)}
      >
        <IonIcon icon={close}></IonIcon>
      </IonButton>
    </IonItem>
  ));
  //   const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
  //     // console.log(props.items);
  //     // console.log("Dragged from index", event.detail.from, "to", event.detail.to);
  //     event.detail.complete(props.items);
  //     props.onListUpdate && props.onListUpdate(props.items);
  //     // console.log(props.items);
  //   };

  const prepareCSVData = (data: ChaBasicListType[], title?: string[]) => {
    var csvArray: string[][] = [];
    if (title) csvArray.push(title);
    data.forEach((infoArray, index) => {
      csvArray.push([
        myUtil.formatNumber(infoArray.a, props.decimalValue),
        myUtil.formatNumber(infoArray.b, props.decimalValue),
      ]);
    });
    console.log(csvArray);
    return csvArray;
  };

  const prepareClipboardData = (data: ChaBasicListType[], units?: string[]) => {
    var lineArray: string[] = [];
    data.forEach((infoArray, index) => {
      if (units && units.length === 2) {
        var line =
        myUtil.formatNumber(infoArray.a, props.decimalValue) + " " + units[0] + " = " + myUtil.formatNumber(infoArray.b, props.decimalValue) + " " + units[1];
        lineArray.push(line);
      }
    });
    var content = lineArray.join("\n");
    return content;
  };

  return (
    <IonGrid className={classes.listContainer}>
      <IonRow>
        <IonCol className="ion-no-padding">
          <IonItem lines="full" color="primary">
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-center">Milligrams</IonCol>
                <IonCol size="2" className="ion-text-center">
                  <IonLabel>⇆</IonLabel>
                </IonCol>
                <IonCol className="ion-text-center">Pounds</IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-no-padding">
          {props.items.length === 0 && (
            <IonItem lines="none">
              <IonLabel>No saved conversion found!</IonLabel>
            </IonItem>
          )}
          <IonList className="ion-no-padding">
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={(event) =>
                props.onListUpdate && props.onListUpdate(event)
              }
            >
              {itemsJSX}
            </IonReorderGroup>
          </IonList>
        </IonCol>
      </IonRow>
      {props.items.length > 0 && (
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton
              color="danger"
              onClick={() => props.onClear && props.onClear()}
            >
              <IonIcon icon={trashOutline} slot="start"></IonIcon>
              <IonLabel>Clear</IonLabel>
            </IonButton>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonButton
              onClick={() => {
                myUtil.copyToClipboard(prepareClipboardData(props.items, ["mg", "lbs"]));
                showToast(
                  "We've copied all saved conversions to your device clipboard. You can now paste it anywhere you want!",
                  "bottom"
                );
              }}
            >
              <IonIcon icon={copyOutline} slot="start"></IonIcon>
              <IonLabel>Copy</IonLabel>
            </IonButton>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonButton
              onClick={() =>
                myUtil.exportToCSV(
                  prepareCSVData(props.items, ["Milligram", "Pound"])
                )
              }
            >
              <IonIcon icon={downloadOutline} slot="start"></IonIcon>
              <IonLabel>Export</IonLabel>
            </IonButton>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default ChaListItems;
